angular.module('eOpti.services').service('svgExport', [
    '$document',
    function ($document) {
        // https://stackoverflow.com/a/37387449/6498038
        let ContainerElements = ['svg', 'g', 'defs', 'pattern']
        let RelevantStyles    = {
            'rect': ['fill', 'stroke', 'stroke-width'],
            'path': ['fill', 'stroke', 'stroke-width'],
            'circle': ['fill', 'stroke', 'stroke-width'],
            'line': ['stroke', 'stroke-width'],
            'text': ['fill', 'font-size', 'text-anchor'],
            'polygon': ['stroke', 'fill']
        }


        function read_Element(ParentNode, OrigData) {
            let Children     = ParentNode.childNodes
            let OrigChildDat = OrigData.childNodes

            for (let cd = 0; cd < Children.length; cd++) {
                let Child = Children[cd]

                let TagName = Child.tagName
                if (ContainerElements.indexOf(TagName) !== -1) {
                    read_Element(Child, OrigChildDat[cd])
                } else if (TagName in RelevantStyles) {
                    let StyleDef = window.getComputedStyle(OrigChildDat[cd])

                    let StyleString = ''
                    for (let st = 0; st < RelevantStyles[TagName].length; st++) {
                        StyleString += RelevantStyles[TagName][st] + ':' + StyleDef.getPropertyValue(RelevantStyles[TagName][st]) + '; '
                    }

                    Child.setAttribute('style', StyleString)
                }
            }

        }

        return {
            getData: function (svgElement) {
                let oDOM = svgElement.cloneNode(true)
                read_Element(oDOM, svgElement)

                return new XMLSerializer().serializeToString(oDOM)
            },
            save: function (svgElement) {
                let data = this.getData(svgElement)
                let svg = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
                let url = URL.createObjectURL(svg)

                let link = document.createElement('a')
                link.setAttribute('target', '_blank')
                let Text = document.createTextNode('Export')
                link.appendChild(Text)
                link.href = url

                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }
    }
])
