angular.module('eOpti.services').factory('dialogs', [
    'ngDialog',
    function (ngDialog) {
        var _defaults = {
            closeByDocument: false,
            showClose: false,
            className: 'ngdialog-theme-plain'
        };
        var templates = {
            confirm: 'app/views/_dialogs/confirm.html',
            inputs: {
                number: 'app/views/_dialogs/inputs/number.html'
            }
        };

        var service = {
            confirm: function (message, options, translate) {
                // sets options for empty if not set
                options = options || {};

                var confirmDefaults = {
                    template: templates.confirm,
                    data: {
                        message: message,
                        translate: (translate === 'true'),

                        confirm: 'Confirm',
                        confirmPanel: 'panel-warning',
                        confirmIcon: false,
                        confirmIconColor: 'btn-success',
                        cancel: 'Cancel',
                        cancelIcon: false,
                        title: 'Confirm',
                        titleIcon: false
                    }
                };

                // merge default data prop with that probably given in options (and backup them)
                confirmDefaults.data = angular.extend(confirmDefaults.data, options.data || {});
                // merge defaults with options (this overrides .data object to that given in options)
                options = angular.extend(_defaults, confirmDefaults, options);
                // restore merged .data
                options.data = confirmDefaults.data;

                return ngDialog.openConfirm(options);
            }
        };

        return service;
    }
]);
