angular.module('eOpti.services.regeneration').service('regenerationTableRenderers', [
    function () {
        return {
            getRenderers: function () {
                return {
                    id: function (value, row) {
                        return '<a class="link" ui-sref="app.regeneration.details({id: ' + value + '})">' + value + '</a>' +
                            (row.notes ? ' <opti-task-icon v-props-name="\'notes\'"></opti-task-icon>' : '') +
                            (row.unread ? ' <span class="label label-danger">' + row.unread + '</span>' : '');
                    }
                }
            }
        }
    }
]);