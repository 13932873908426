angular.module('eOpti.services').service('error', [
    '$rootScope',
    function ($rootScope) {
        this.request = function(error, lang) {
            if (error.status === 422) {
                angular.forEach(error.data.errors, function (error) {
                    angular.forEach(error, function (message) {
                        $rootScope.$emit('notify', {
                            translate: false,
                            message: message,
                            status: 'danger'
                        })
                    })
                })
            } else if(typeof lang !== 'undefined') {
                $rootScope.$emit('error', lang)
            }
        }
    }
])