angular.module('eOpti.services').service('js', [function () {
    this.isEmpty = function(object) {
        return Object.keys(object).length ? false : true;
    };

    this.inArray = function(value, tab) {
        var result = false;

        angular.forEach(tab, function(t) {
            if(t == value) {
                result = true;
            }
        });

        return result;
    };
}]);
