angular.module('eOpti.services').service('activity', ['$http', 'uac', function($http, uac) {
    this.increase = data => {
        return $http.post('api/department/activity/increase', data)
    }

    this.increaseForDepartment = departmentId => {
        return this.increase({
            departmentId: departmentId
        })
    }

    this.increaseForPatient = patientId => {
        return this.increase({
            patientId: patientId
        })
    }

    this.increaseForCurrentUser = () => {
        return this.increase({
            userId: uac.user.id
        })
    }
}])
