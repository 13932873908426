angular.module('eOpti.services').service('audio', [
    function () {
        // podpinamy się pod play() i odpalamy własny kod który usunie błąd "DOMException: play() failed because the user didn't interact with the document first."
        Audio.prototype.play = (function (play) {
            return function () {
                let audio   = this,
                    args    = arguments,
                    promise = play.apply(audio, args)

                if (promise !== undefined) {
                    promise.catch(_ => {
                        // nic nie robimy w przypadku błędu
                    })
                }
            }
        })(Audio.prototype.play)

        return {
            load: function(path) {
                var audio = new Audio(path),
                    self = this;

                self.audio = audio;

                var startAudio = function() {
                    self.audio.play();
                    document.removeEventListener('touchstart', self.startAudio, false);
                };

                self.startAudio = startAudio;

                var pauseAudio = function() {
                    self.audio.pause();
                    self.audio.removeEventListener('play', self.pauseAudio, false);
                };

                self.pauseAudio = pauseAudio;

                document.addEventListener('touchstart', self.startAudio, false);
                self.audio.addEventListener('play', self.pauseAudio, false);

                return audio;
            }
        };
    }
]);
