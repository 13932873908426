angular.module('eOpti.services').factory('formValidate', ['$filter', function($filter) {
    return {
        validate(input, type) {
            return input.$dirty && input.$error[type]
        },
        textInfo: {
            required: $filter('translate')('validation.formValidate.required'),
            requiredall: $filter('translate')('validation.formValidate.requiredall'),
            email: $filter('translate')('validation.formValidate.email'),
            url: $filter('translate')('validation.formValidate.url'),
            minlength(min) {
                return $filter('translate')('validation.formValidate.minlength.PART1') + ' ' + min +  ' ' +
                    $filter('translate')('validation.formValidate.minlength.PART2');
            },
            maxlength(max) {
                return $filter('translate')('validation.formValidate.maxlength.PART1') + ' ' + max +  ' ' +
                    $filter('translate')('validation.formValidate.maxlength.PART2');
            },
            equallength(num) {
                return $filter('translate')('validation.formValidate.equallength.PART1') + ' ' + num +  ' ' +
                    $filter('translate')('validation.formValidate.equallength.PART2');
            },
            fromtolength(from, to) {
                return $filter('translate')('validation.formValidate.fromtolength.PART1') + ' ' + from +  ' ' +
                    $filter('translate')('validation.formValidate.fromtolength.PART2') + ' ' + to + ' ' +
                    $filter('translate')('validation.formValidate.fromtolength.PART3');
            },
            pesel: $filter('translate')('validation.formValidate.pesel'),
            date: $filter('translate')('validation.formValidate.date'),
            number: $filter('translate')('validation.formValidate.number'),
            option: $filter('translate')('validation.formValidate.option'),
            exists: $filter('translate')('validation.formValidate.exists')
        }
    }
}])