angular.module('eOpti.services').provider('$storage', function () {
    var _links = {},
        _expire = 0;

    this.setLinks = function (obj) {
        _links = obj;
    };

    this.setExpire = function (ts) {
       _expire = ts;
    };

    this.$get = ['$http', '$q', '$rootScope', 'requestStorage', function ($http, $q, $rootScope, requestStorage) {
        var _getObj = function (name) {
            var result = {};

            angular.forEach(_links, function (obj, nameLink) {
                if (nameLink == name) {
                    result = obj;
                }
            });

            return result;
        };

        var get = function (name) {
            var defer = $q.defer(),
                obj = _getObj(name);

            if (obj.link) {
                requestStorage(name, {
                    url: obj.link,
                    method: 'GET'
                }, {
                    expires: Object.keys(obj).indexOf('expire') > -1 ? obj.expire : _expire
                }).then(function(res) {
                    defer.resolve(angular.copy(res.data));
                }, function() {
                    var errorMessage = 'default.storage.notify.error.DEFAULT';

                    $rootScope.$emit('error', errorMessage);

                    defer.reject(errorMessage);
                });
            } else {
                var errorMessage = 'default.storage.notify.error.ALLOW';

                $rootScope.$emit('error', errorMessage);

                defer.reject(errorMessage);
            }

            return defer.promise;
        };

        return {
            get: get,
            all: function () {
                var defer = $q.defer(),
                    tab = [];

                angular.forEach(arguments, function (args) {
                    tab.push(get(args));
                });

                return $q.all(tab);
            },
            find: function (obj, key, value) {
                var result = {};

                angular.forEach(obj, function(o) {
                    if(o[key] == value) {
                        result = o;
                    }
                });

                return result;
            }
        };
    }];
});