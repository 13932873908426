angular.module('eOpti.services.task').service('taskListIcons', [function() {
    let getAttributesFromInt = int => {
        let attributes = {
            putBack: {
                main: 'task-icon',
                sub: 'put-back',
                title: 'tasks.attributes.PUTBACK',
                bit: 0
            },
            deadline: {
                main: 'task-icon',
                sub: 'deadline',
                title: 'tasks.attributes.DEADLINE',
                bit: 1
            },
            printed: {
                main: 'task-icon',
                sub: 'printed',
                title: 'tasks.attributes.PRINTED',
                bit: 2
            },
            orderByAdmin: {
                main: 'fa',
                sub: 'fa-user fa-2x',
                title: 'tasks.attributes.ORDER_BY_ADMIN',
                bit: 6
            },
            greenPutBack: {
                main: 'task-icon',
                sub: 'green-put-back',
                title: 'tasks.attributes.GREEN_PUT_BACK',
                bit: 8
            },
            measurementsOk: {
                main: 'task-icon',
                sub: 'ok',
                title: 'tasks.attributes.MEASURMENTOK',
                bit: 16
            },
            measurementsNotOk: {
                main: 'task-icon',
                sub: 'not-ok',
                title: 'tasks.attributes.MEASURMENTNOTOK',
                bit: 17
            },
            taskDuplicate: {
                main: 'task-icon',
                sub: 'duplicate',
                title: 'tasks.attributes.DUPLICATE',
                bit: 15
            },
            forSc: {
                main: 'task-icon',
                sub: 'sc',
                title: 'tasks.attributes.SERVICECONTRACT',
                bit: 4
            },
            service: {
                main: 'fa',
                sub: 'fa-2x fa-recycle text-success',
                title: 'tasks.attributes.SERVICE',
                bit: 20
            },
            simple: {
                main: 'task-icon-letter',
                sub: 'simple',
                title: '',
                bit: 21
            }

            //'sc': ,
            //'pakiet',
        }

        let enabledAttributes = []
        angular.forEach(attributes, value => {
            if((int & Math.pow(2, value.bit)) === Math.pow(2, value.bit)) {
                enabledAttributes.push(value)
            }
        })

        return enabledAttributes
    }

    return {
        getRenderer: function () {
            return function(row, columns) {
                let columnCount = 0,
                    shouldCount = true,
                    count       = 0,
                    rowspanned  = 0

                angular.forEach(columns, function (column) {
                    if (column.visible) {
                        count++
                    }

                    if (column.visible && shouldCount) {
                        columnCount += 1
                    }

                    if (column.should_row_span) {
                        shouldCount = false
                        rowspanned++
                    }
                })

                if (columnCount > 0) columnCount--

                let attributes = getAttributesFromInt(row.attributes),
                    icons      = ''

                angular.forEach(attributes, function (attribute) {
                    if(attribute.bit === 4) {
                        icons += '<a ui-sref="app.task.detail({id: ' + row.serviceContractId + '})">'
                    }

                        icons += '<span class="' + attribute.main + ' ' + attribute.sub + '"/>'

                    if(attribute.bit === 4) {
                        icons += '</a>'
                    }
                })

                if(row.satisfaction_guarantee) {
                    icons += '<span class="task-icon guarantee" style="padding:0 5px" title="{{::\'tasks.SATISFACTION\'|translate}}"></span>'
                }

                if(row.serviceContract) {
                    icons += '<span clsss="bold text-expression" style="font-size:22px;padding:0 5px">US</span>'
                }

                if(row.packId) {
                    icons += '<span clsss="bold text-expression" style="font-size:22px;padding:0 5px">P</span>'
                }

                if(row.notes) {
                    icons += '<span style="font-size:22px;padding-left:10px" class="fa fa-file text-warning" title="{{::\'notes.LEFT\'|translate}}"></span>'
                }

                if(row.dbl === 0) {
                    icons += '<span style="font-size:22px;padding-left:10px" class="fa fa-crop warn" ' +
                        'title="{{::\'tasks.attributes.NOSHAPE\'|translate}}"></span>'
                }

                if(row.sun) {
                    icons += '<span style="font-size:22px;padding-left:10px" class="fa fa-sun-o text-warning"></span>'
                }

                let html = '<td class="task-icons" colspan="' + columnCount + '">' + icons + '</td>'

                for(let i = 0; i < count - columnCount - rowspanned; i++) {
                    html += '<td></td>'
                }

                return html
            }
        }
    }
}])