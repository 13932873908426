angular.module('eOpti.services').service('link', ['$q', '$http',
    function ($q, $http) {
        return {
            activate: function(url) {
                var defer = $q.defer();

                $http.post('api/temporary-link', {
                    url: url
                }).then(function(res) {
                    defer.resolve(res.data);
                }, function (error) {
                    defer.reject(error);
                });

                return defer.promise;
            }
        }
    }]);
