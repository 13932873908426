angular.module('eOpti.services').service('uiSelectHelper', ['$filter',
    function ($filter) {
         __self = {
            data: {
                result: [],
                textNewPosition: $filter('translate')('default.uiselect.NEW'),
                textSuggest: $filter('translate')('default.uiselect.BASE')
            },

            setData: function(result) {
                __self.data.result = result;
            },

            setText: function(text) {
                __self.data.textSuggest = text;
            },

            inArray: function(tab, search) {
                var tabLower = tab.map(function(value) {
                    return value.toLowerCase();
                });

                if(tabLower.indexOf(search.toLowerCase()) !== -1) {
                    return true;
                }

                return false;
            },

            getSuggest: function (tab, search) {
                if(typeof tab === 'undefined') {
                    var newTab = [];
                } else {
                    var newTab = tab.slice();
                }

                if (search && !__self.inArray(newTab, search)) {
                    newTab.unshift(search);
                }

                return newTab;
            },

            highlightNewItem: function (item) {
                if (__self.data.result && __self.data.result.indexOf(item) !== -1) {
                    return __self.data.textSuggest;
                } else {
                    return __self.data.textNewPosition;
                }
            }
        };

        return __self;
    }]);
