import * as enums from '../../../app/vue/enums/Enums'

angular.module('eOpti.services').provider('$enum', function () {
    var _expire = 0;

    this.setExpire = function(ts) {
        _expire = ts;
    };

    this.$get = ['$http', '$q', '$rootScope', 'requestStorage', function($http, $q, $rootScope, requestStorage) {
        var get = function (name) {
            let q = $q.defer()
            q.resolve(enums[`Enum${name}`].simpleItems())
            return q.promise
        };

        return {
            get: get,
            name: function (members, key) {
                var result = '';

                angular.forEach(members, function (member) {
                    if (member.id == key) {
                        result = member.name;
                    }
                });

                return result;
            },
            all: function () {
                var defer = $q.defer(),
                    tab = [];

                angular.forEach(arguments, function(args) {
                    tab.push(get(args));
                });

                return $q.all(tab);
            }
        };
    }];
});
