angular.module('eOpti.services').factory('requestStorage', [
    '$http',
    '$localStorage',
    '$q',
    function ($http, $localStorage, $q) {
        var gc = function () {
            angular.forEach($localStorage, function (val, key) {
                if (key.indexOf('request-') > -1 &&
                    val.expires &&
                    val.modified + val.expires <= Math.floor(new Date().getTime() / 1000)
                ) {
                    delete $localStorage[key];
                }
            });
        };

        var activeRequests = []

        return function (tag, request, options) {
            gc();

            var defer = $q.defer();

            tag = 'request-' + tag;

            if (!$localStorage[tag]) {
                if (!activeRequests[tag]) {
                    var query = $http(request)
                    query.then(function (response) {

                        var defaults = {
                            modified: Math.floor(new Date().getTime() / 1000),
                            expires: false
                        };

                        defer.resolve(response);

                        $localStorage[tag] = angular.extend(defaults, options, {response: angular.copy(response)});
                    }, function (error) {
                        defer.reject(error);
                    });
                    activeRequests[tag] = query
                } else {
                    activeRequests[tag].then(function (response) {
                        defer.resolve(response)
                        delete activeRequests[tag]
                    }, function (error) {
                        defer.reject(error)
                        delete activeRequests[tag]
                    })
                }
            } else {
                defer.resolve($localStorage[tag].response);
            }

            return defer.promise;
        }
    }
]);
