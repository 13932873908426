angular.module('eOpti.services').service('layoutClasses', [
    '$document',
    '$filter',
    function ($document, $filter) {
        class FocusableObject {
            constructor() {
                this.focused = false
                this.class   = ''
                this.name    = ''
            }

            isFocused() {
                return this.focused
            }

            setFocus(focused) {
                this.focused = focused
            }

            getClass() {
                return this.class + (this.isFocused() ? ' focused' : '')
            }

            getName() {
                return this.name
            }
        }

        class LayoutObjectAbstract extends FocusableObject {
            constructor(conf) {
                super()

                this.id       = conf.id
                this.type     = conf.type
                this.x        = conf.x
                this.y        = conf.y
                this.rotation = conf.rotation

                this.width  = conf.width
                this.height = conf.height
                this.class  = conf.class
                this.name   = conf.name

                this.previous = conf.previous
                this.next     = conf.next

                this.min = conf.min || 1
                this.max = conf.max || 18

                this.alert = conf.alert
                this.alerts = conf.alerts

                this.editable = false

                this.applyFn         = conf.applyFn
                this.roundingFn      = conf.roundingFn
                this.roundingAngleFn = conf.roundingAngleFn
                this.activateFn      = conf.activateFn

                this.upload = conf.upload

                this.conf = conf
            }

            getClass() {
                /*
                return [
                    'critical' => 5,
                    'alert'    => 10,
                    'warning'  => 15,
                    'notice'   => 20,
                    'info'     => 25
                ];
                 */
                let css = super.getClass()

                switch (this.alert) {
                    case 5:
                    case 10:
                        css += ' object-alert'
                        break

                    case 15:
                    case 20:
                    case 25:
                        css += ' object-warning'
                        break
                }

                return css
            }

            setActive() {
                this.activateFn(this)
            }

            setEditable(isEditable) {
                this.editable = isEditable
            }

            offsetX() {
                return Math.round(this.width / 2)
            }

            offsetY() {
                return Math.round(this.height / 2)
            }

            offset() {
                return Math.max(this.offsetX(), this.offsetY())
            }

            body() {
                return this.background() + this.contentSvg() + this.rotatePin()
            }

            contentSvg() {
                return '<svg ng-mousedown="object.mouseDown($event, object)" ng-attr-x="{{ object.offset() }}" ng-attr-y="{{ object.offset() }}" ng-attr-width="{{ object.width }}" ng-attr-height="{{ object.height }}">' + this.content() + '</svg>'
            }

            background() {
                return `<rect 
                        ng-attr-x='{{ object.offset() }}' 
                        ng-attr-y='{{ object.offset() }}' 
                        ng-attr-width='{{ object.width }}' 
                        ng-attr-height='{{ object.height }}' 
                        ng-mousedown="object.mouseDown($event, object)"
                        ></rect>`
            }

            content() {
                return `placeholder`
            }

            rotatePin() {
                return `<circle 
                        ng-if="object.editable" 
                        r="5" 
                        ng-attr-cx="{{ object.offset() + object.width + 5 }}" 
                        ng-attr-cy="{{ object.offset() - 5 }}" 
                        ng-mousedown="object.rotateMouseDown($event, object)"
                        fill="white" 
                        stroke="black"></circle>`
            }

            onClick(object) {

            }

            mouseDown(event, object) {
                if (!this.editable) {
                    this.onClick(object)
                    return
                }

                if (event.which !== 1) {
                    return
                }
                event.preventDefault()

                this.setActive()

                let svg   = $('#floor')[0]
                let start = svg.createSVGPoint()
                let pt    = svg.createSVGPoint()

                start.x = event.clientX
                start.y = event.clientY
                start   = start.matrixTransform(svg.getScreenCTM().inverse())

                object.original = {
                    x: object.x,
                    y: object.y
                }

                let self        = this
                let mouseMoveFn = function (mouseEvent) {
                    pt.x = mouseEvent.clientX
                    pt.y = mouseEvent.clientY
                    pt   = pt.matrixTransform(svg.getScreenCTM().inverse())

                    self.applyFn(function () {
                        object.x = self.roundingFn(object.original.x - (start.x - pt.x), 'width', object.width)
                        object.y = self.roundingFn(object.original.y - (start.y - pt.y), 'height', object.height)
                    })
                }
                let mouseUpFn   = function () {
                    $document.off('mousemove', mouseMoveFn)
                    $document.off('mouseup', mouseUpFn)
                }
                $document.on('mousemove', mouseMoveFn)
                $document.on('mouseup', mouseUpFn)
            }

            rotateMouseDown(event, object) {
                if (event.which !== 1) {
                    return
                }
                event.preventDefault()

                let svg        = $('#floor')[0]
                let start      = svg.createSVGPoint()
                start.x        = object.x + object.width / 2
                start.y        = object.y + object.height / 2
                let pt         = svg.createSVGPoint()
                let r2d        = 180 / Math.PI
                let startAngle = r2d * Math.atan2(start.y, start.x)

                let self        = this
                let mouseMoveFn = function (mouseEvent) {
                    pt.x = mouseEvent.clientX
                    pt.y = mouseEvent.clientY
                    pt   = pt.matrixTransform(svg.getScreenCTM().inverse())

                    let angle = r2d * Math.atan2(pt.y - start.y, pt.x - start.x)

                    self.applyFn(function () {
                        object.rotation = self.roundingAngleFn(angle + startAngle)
                    })
                }
                let mouseUpFn   = function () {
                    $document.off('mousemove', mouseMoveFn)
                    $document.off('mouseup', mouseUpFn)
                }
                $document.on('mousemove', mouseMoveFn)
                $document.on('mouseup', mouseUpFn)
            }

            toJSON() {
                return {
                    id: this.id,
                    type: this.class.replace('layout-object-', ''),
                    name: this.name,
                    x: this.x,
                    y: this.y,
                    rotation: this.rotation,
                    width: this.width,
                    height: this.height,
                    min: this.min,
                    max: this.max
                }
            }
        }

        class Point extends FocusableObject {
            constructor(x, y, room) {
                super()

                this.x        = x
                this.y        = y
                this.room     = room
                this.class    = 'anchor'
                this.editable = false
            }

            setEditable(isEditable) {
                this.editable = isEditable
            }

            remove() {
                let self         = this
                this.room.points = this.room.points.filter(function (el) {
                    return el !== self
                })
                this.room.invalidateAnchors()
                this.room.setActive(false)
            }

            setActive() {
                this.room.setActive(this)
            }

            toJSON() {
                return {
                    x: this.x,
                    y: this.y
                }
            }
        }

        class PointAdder extends Point {
            constructor(x, y, room, idx) {
                super(x, y, room)
                this.idx   = idx
                this.class = 'anchor-adder'
            }

            addToRoom() {
                let point = new Point(this.x, this.y, this.room)
                this.room.points.splice(this.idx, 0, point)
                this.room.invalidateAnchors()
                this.room.setActive(point)
                return true
            }
        }

        class RoomAbstract extends FocusableObject {
            constructor(conf) {
                super()

                this.id                = conf.id
                this.class             = conf.type
                this.pointsWithAnchors = false
                this.editable          = false
                this.name              = conf.name

                this.applyFn    = conf.applyFn
                this.roundingFn = conf.roundingFn
                this.activateFn = conf.activateFn

                this.box = {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    center: {
                        x: 0,
                        y: 0
                    }
                }

                this.points = []
                for (let i = 0; i < conf.points.length; i++) {
                    let p = conf.points[i]
                    this.points.push(new Point(p.x, p.y, this))
                }
                this.recalculateBox()
            }

            setActive(elem) {
                if (!this.editable) return
                if (!elem) elem = this
                this.activateFn(elem)
            }

            setEditable(isEditable) {
                this.editable = isEditable

                for (let i = 0; i < this.points.length; i++) {
                    this.points[i].setEditable(isEditable)
                }

                this.invalidateAnchors()
            }

            getPath() {
                let path = []
                for (let i = 0; i < this.points.length; i++) {
                    path.push(this.points[i].x + ',' + this.points[i].y)
                }
                return 'M' + path.join(' L') + ' Z'
            }

            getPoints() {
                let points  = []
                let nextIdx = 0

                if (this.editable) {
                    if (!this.pointsWithAnchors) {
                        for (let i = 0; i < this.points.length; i++) {
                            this.points[i].this = this
                            points.push(this.points[i])

                            nextIdx = i < this.points.length - 1 ? i + 1 : 0
                            let p   = {
                                x: Math.round((this.points[i].x + this.points[nextIdx].x) / 2),
                                y: Math.round((this.points[i].y + this.points[nextIdx].y) / 2),
                            }
                            points.push(new PointAdder(p.x, p.y, this, i + 1))
                        }
                        this.pointsWithAnchors = points
                        this.recalculateBox()
                    }
                    points = this.pointsWithAnchors
                }

                return points
            }

            recalculateBox() {
                let xs = []
                let ys = []

                for (let i = 0; i < this.points.length; i++) {
                    let point = this.points[i]
                    xs.push(point.x)
                    ys.push(point.y)
                }

                this.box.x        = Math.min.apply(Math, xs)
                this.box.y        = Math.min.apply(Math, ys)
                this.box.width    = Math.max.apply(Math, xs) - this.box.x
                this.box.height   = Math.max.apply(Math, ys) - this.box.y
                this.box.center.x = this.box.x + this.box.width / 2
                this.box.center.y = this.box.y + this.box.height / 2
            }

            invalidateAnchors() {
                this.pointsWithAnchors = false
            }

            pinMouseDown(event, point) {
                if (event.which !== 1) {
                    return
                }
                event.preventDefault()

                if (point instanceof PointAdder) {
                    return point.addToRoom()
                }

                point.setActive()

                let circle = $(event.target)
                let svg    = circle.parents('svg').first()[0]
                let pt     = svg.createSVGPoint()

                pt.x = event.pageX
                pt.y = event.pageY

                let mouseMoveFn = function (mouseEvent) {
                    pt.x = mouseEvent.clientX
                    pt.y = mouseEvent.clientY
                    pt   = pt.matrixTransform(svg.getScreenCTM().inverse())

                    point.room.applyFn(function () {
                        point.x = point.room.roundingFn(pt.x, 'width')
                        point.y = point.room.roundingFn(pt.y, 'height')
                        point.room.invalidateAnchors()
                    })
                }
                let mouseUpFn   = function () {
                    $document.off('mousemove', mouseMoveFn)
                    $document.off('mouseup', mouseUpFn)
                }
                $document.on('mousemove', mouseMoveFn)
                $document.on('mouseup', mouseUpFn)
            }

            toJSON() {
                return {
                    id: this.id,
                    type: this.class,
                    name: this.name,
                    points: this.points
                }
            }
        }

        class LayoutObjectSnapshotableAbstract extends LayoutObjectAbstract {
            constructor(conf) {
                let defaults = {
                    highlight: false,
                    hover: false
                }
                conf         = Object.assign({}, defaults, conf)
                super(conf)
            }

            setSnapshot(item) {
                this.snapshot = item
            }

            getSnapshotContent() {
                if (this.snapshot) {
                    return (this.snapshot.turnover / 1000).toFixed(1).replace('.', ',')
                }

                return ''
            }

            getClass() {
                let css = super.getClass()

                if (this.conf.highlight) {
                    css += ' layout-object-highlighted'
                }

                if (this.conf.hover) {
                    css += ' layout-object-hover'
                }

                return css
            }

            setHighlight(enable) {
                if (typeof enable !== 'undefined') {
                    this.conf.highlight = enable
                } else {
                    this.conf.highlight = !this.conf.highlight
                }

                return this.conf.highlight
            }

            setHover(enable) {
                this.conf.hover = enable
            }

            isHighlighted() {
                return this.conf.highlight
            }

            content() {
                return `
                            <g 
                            ng-attr-transform="rotate({{-object.rotation}} {{ object.width / 2 }} {{ object.height / 2 }})"
                            ng-attr-width="{{ object.width }}"
                            ng-attr-height="{{ object.height }}"
                            >
                                <text
                                ng-attr-x="{{ object.width / 2 }}"
                                y="1em"
                                text-anchor="middle"
                                >{{ object.getName() }}</text>
                                <text
                                ng-attr-x="{{ object.width / 2 }}"
                                y="1.9em"
                                text-anchor="middle"
                                >{{ object.getSnapshotContent() }}</text>
                            </g>
                        `
            }

            onClick(object) {
                this.conf.toggle(this)
            }
        }

        return {
            definitions: {
                FocusableObject: FocusableObject,
                RoomAbstract: RoomAbstract,
                LayoutObjectAbstract: LayoutObjectAbstract,
                LayoutObjectSnapshotableAbstract: LayoutObjectSnapshotableAbstract
            },
            objects: {
                panel: class LayoutObjectPanel extends LayoutObjectSnapshotableAbstract {
                    constructor(conf) {
                        let defaults = {
                            type: 'panel',
                            class: 'layout-object-panel',
                            width: 90,
                            height: 30,
                            previous: 0,
                            next: 0,
                            min: 1,
                            max: 18,
                            upload: ''
                        }
                        conf         = Object.assign({}, defaults, conf)
                        super(conf)
                    }
                },
                shelve: class LayoutObjectShelve extends LayoutObjectSnapshotableAbstract {
                    constructor(conf) {
                        let defaults = {
                            type: 'shelve',
                            class: 'layout-object-shelve',
                            width: 90,
                            height: 30,
                            previous: 0,
                            next: 0,
                            min: 1,
                            max: 96,
                            upload: ''
                        }

                        conf = Object.assign({}, defaults, conf)
                        super(conf)
                    }
                },
                custom: class LayoutObjectCustom extends LayoutObjectSnapshotableAbstract {
                    constructor(conf) {
                        let defaults = {
                            type: 'shelve',
                            class: 'layout-object-custom',
                            width: 90,
                            height: 30,
                            previous: 0,
                            next: 0,
                            min: 1,
                            max: 999,
                            upload: ''
                        }

                        conf = Object.assign({}, defaults, conf)
                        super(conf)
                    }
                },
                glasscase: class LayoutObjectGlassCase extends LayoutObjectAbstract {
                    constructor(conf) {
                        let defaults = {
                            type: 'glasscase',
                            class: 'layout-object-glasscase',
                            width: 50,
                            height: 50,
                            previous: 0,
                            next: 0,
                            min: 1,
                            max: 10,
                            upload: ''
                        }

                        conf = Object.assign({}, defaults, conf)
                        super(conf)
                    }

                    content() {
                        return `<text 
                        text-anchor="middle" 
                        ng-attr-x="{{ object.width / 2 }}" 
                        ng-attr-y="{{ object.height / 2 }}"
                        >
                            <tspan alignment-baseline="middle">{{ object.getName() }}</tspan>
                        </text>
                        `
                    }

                    onClick(object) {
                        this.conf.toggle(this)
                    }
                },
                advertisement: class LayoutObjectGlassCase extends LayoutObjectAbstract {
                    constructor(conf) {
                        let defaults = {
                            type: 'advertisement',
                            class: 'layout-object-advertisement',
                            width: 50,
                            height: 50,
                            previous: 0,
                            next: 0,
                            min: 1,
                            max: 10,
                            upload: ''
                        }

                        conf = Object.assign({}, defaults, conf)
                        super(conf)
                    }

                    content() {
                        return `<text 
                        text-anchor="middle" 
                        ng-attr-x="{{ object.width / 2 }}" 
                        ng-attr-y="{{ object.height / 2 }}"
                        >
                            <tspan alignment-baseline="middle">{{ object.getName() }}</tspan>
                        </text>
                        `
                    }

                    onClick(object) {
                        this.conf.toggle(this)
                    }
                }
            },
            rooms: {
                'room-main': class RoomMain extends RoomAbstract {
                },
                'room-doctor': class RoomDoctor extends RoomAbstract {
                },
                'room-closet': class RoomCloset extends RoomAbstract {
                },
                'room-furniture': class RoomFurniture extends RoomAbstract {
                }
            }
        }
    }
])
