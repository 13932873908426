angular.module('eOpti.services.reclamation').service('reclamationTableRenderers', [
    function () {
        return {
            getRenderers: function (type) {
                return {
                    id: function (value, row) {
                        return '<a class="link" ui-sref="' + (type === 'tasks' ? 'app.tasks.reclamation.details' : 'app.sale.reclamation.details') +
                            '({id: ' + value + '})">' + value + '</a>' + (row.notes ? ' <opti-task-icon v-props-name="\'notes\'"></opti-task-icon>' : '')
                    }
                }
            }
        }
    }
])