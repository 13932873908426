angular.module('eOpti.services').service('months', [
    function () {
        var service = {
            get: function() {
                var result = [];

                for(var i = 1; i <= 12; ++i) {
                    result.push(i);
                }

                return result;
            },

            getToMonth: function(month) {
                var result = [];

                for(var i = 1; i <=  month; ++i) {
                    result.push(i);
                }

                return result;
            },

            getFromMonth: function(month) {
                var result = [];

                for(var i = month; i <= 12; ++i) {
                    result.push(i);
                }

                return result;
            },

            parse: function(tab) {
                var result = [];

                angular.forEach(tab, function(month) {
                    result.push({
                        id: month,
                        name: 'default.months.' + month
                    });
                });

                return result;
            }
        };

        return service;
    }]);
