angular.module('eOpti.services.receipt').service('receiptNoticeService', [
    '$rootScope', '$http', '$q',
    function ($rootScope, $http, $q) {
        var service = {
            get: function(module, id) {
                var defer = $q.defer();

                var args = {
                    module: module,
                    id: id
                };

                $http.post('api/register/notice', args).then(function(res) {
                    if(res.data.all) {
                        defer.resolve(res.data);
                    } else {
                        defer.reject();
                    }
                }, function(error) {
                    $rootScope.$emit('error', 'register.notify.error.NOTGETCHANGE');

                    defer.reject();
                });

                return defer.promise;
            }
        };

        return service;
    }
]);
