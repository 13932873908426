angular.module('eOpti.services.interview').service('pathService', ['$q', '$storage', function ($q, $storage) {
    this.load = function (type) {
        var q = $q.defer();

        $storage.get('interviewPath' + type).then(function(path) {
            angular.forEach(path, function(p) {
                angular.forEach(p, function(element) {
                    if(Object.keys(element).indexOf('links') > -1) {
                        angular.forEach(element.links, function(link, link_key) {
                            angular.forEach(path, function(object) {
                                angular.forEach(object, function(obj, key) {
                                    if(link == key) {
                                        element.links[link_key] = obj;
                                    }
                                });
                            });
                        });
                    }
                });
            });

            q.resolve(path.reverse());
        });

        return q.promise;
    };

    var findPoint = this.findPoint = function (paths, dotId) {
        var found = false;
        angular.forEach(paths, function (tier) {
            angular.forEach(tier, function (dot) {
                if (dot.id === dotId) {
                    found = dot
                }
            })
        });
        return found
    };

    this.setupFor = function (paths, firstSide) {
        var rVisus1   = findPoint(paths, 'rVisus1'),
            lVisus1   = findPoint(paths, 'lVisus1'),
            lVisus3   = findPoint(paths, 'lVisus3'),
            rVisus3   = findPoint(paths, 'rVisus3'),
            binVisus1 = findPoint(paths, 'binVisus1'),
            lCZ1      = findPoint(paths, 'lCZ1'),
            rCZ1      = findPoint(paths, 'rCZ1'),
            lEE1      = findPoint(paths, 'lEE1'),
            rEE1      = findPoint(paths, 'rEE1');

        if (firstSide === 'right') {
            rVisus3.links = [];
            lVisus3.links = [];
            lVisus3.links.push(binVisus1);

            lVisus1.links = [lCZ1, lEE1, rVisus3];
            rVisus1.links = [rCZ1, rEE1];
        } else if (firstSide === 'left') {
            lVisus3.links = [];
            rVisus3.links = [];
            rVisus3.links.push(binVisus1);

            lVisus1.links = [lCZ1, lEE1];
            rVisus1.links = [rCZ1, rEE1, lVisus3];
        } else {
            return false;
        }
    };

    this.setupForAcuityNoCorrection = function (paths, firstSide) {
        var visus1 = findPoint(paths, 'visus1'),
            visus2 = findPoint(paths, 'visus2');

        if(firstSide === 'left') {
            visus1.x = '70%';
            visus2.x = '30%';
        } else {
            return false;
        }
    };

    this.setupForAcuityWithCorrection = function (paths, firstSide) {
        var visus1 = findPoint(paths, 'visus1'),
            visus2 = findPoint(paths, 'visus2'),
            cz1    = findPoint(paths, 'CZ1'),
            cz2    = findPoint(paths, 'CZ2'),
            ee1    = findPoint(paths, 'EE1'),
            ee2    = findPoint(paths, 'EE2');

        if(firstSide === 'left') {
            visus1.x = '70%';
            cz1.x    = '65%';
            ee1.x    = '75%';
            visus2.x = '30%';
            cz2.x    = '25%';
            ee2.x    = '35%';
        } else {
            return false;
        }
    };
}]);
