angular.module('eOpti.services').factory('clipboardService', [
    '$document', '$window',
    function ($document, $window) {
        function createNode(text) {
            var node             = $document[0].createElement('input')
            node.style.position  = 'absolute'
            node.style.left      = '10px'
            node.style.top       = '10px'
            node.value           = text
            node.contentEditable = true
            node.readOnly        = false

            return node
        }

        function copyNode(node) {
            var doc = $document[0]

            if (doc.body.createTextRange) {
                var textRange = doc.body.createTextRange()
                textRange.moveToElementText(node)
                textRange.select()
                textRange.execCommand('copy')
                return true
            }

            var range = doc.createRange()
            range.selectNodeContents(node)
            var selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(range)

            node.select()
            node.setSelectionRange(0, 999999)

            return doc.execCommand('copy')
        }

        return {
            copy: function (text) {
                var node = createNode(text)
                $document[0].body.appendChild(node)
                var copied = copyNode(node)
                $document[0].body.removeChild(node)
                return copied
            }
        }
    }
])
