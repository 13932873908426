angular.module('eOpti.services').service('memory', [
    '$rootScope', '$localStorage', '$filter',
    function ($rootScope, $localStorage, $filter) {
        if(!$localStorage['memory']) {
            $localStorage['memory'] = {}
        }

        var _count = 5,
            _data = $localStorage['memory'];

        var service = {
            data: _data,

            forgetObject: function (type, objId) {
                for (var i = 0; i < _data[type].length; i++) {
                    if (_data[type][i].id == objId) {
                        _data[type].splice(i, 1);
                    }
                }
            },

            rememberObject: function (type, obj) {
                if (typeof _data[type] === 'undefined' || !angular.isArray(_data[type])) {
                    _data[type] = [];
                }

                service.forgetObject(type, obj.id);

                obj.date = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm');

                _data[type].unshift(obj);
                if (_data[type].length > _count) {
                    while (_data[type].length > _count) {
                        _data[type].pop();
                    }
                }
            },

            getObjects: function (type) {
                return _data[type];
            },

            isEmpty: function (type) {
                if (typeof type === 'undefined') {
                    for (var property in _data) {
                        if (_data.hasOwnProperty(property) && angular.isArray(_data[property]) && _data[property].length) {
                            return false;
                        }
                    }
                    return true;
                }

                if (_data.hasOwnProperty(type) && angular.isArray(_data[type])) {
                    return _data[type].length == 0;
                } else {
                    return true; // if there's no field named <type> then it is empty
                }
            }
        };

        return service;
    }]);
