angular.module('eOpti.services').factory('dashboard', [
    '$rootScope',
    '$http',
    'VuexStore',
    function ($rootScope, $http, VuexStore) {

        $rootScope.$on('dashboard.fetch', () => {
            //service.fetch()
            VuexStore.dispatch('dashboard/fetch')
        })

        return {
            state: VuexStore.getters['dashboard/status'],
            data: VuexStore.getters['dashboard/all'],
            previousAssemblies: VuexStore.getters['dashboard/previousAssemblies'],

            loadPreviousAssemblies() {
                VuexStore.dispatch('dashboard/fetchPreviousAssemblies')
            },
            fetch() {
                VuexStore.dispatch('dashboard/fetch')
            },
            loadSingle(key, val) {
                // noop
                console.warn('dashboard.loadSingle is deprecated!')
            },
            load(data) {
                // noop
                console.warn('dashboard.load is deprecated!')
            },
            update(counter, field, value) {
                // noop
                console.warn('dashboard.update is deprecated!')
            }
        }
    }
])
